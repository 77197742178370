<template>
  <v-card flat style="background-color: #f4f6f9" class="pt-1">
    <v-container fluid px-0 pt-1 v-if="Object.keys(zone).length > 0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6" class="pr-1">
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Name</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.name"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Short Name</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.short_name"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Zone Code</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.zcode"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Time Zone</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.timezone"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Address</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.address"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Latitude</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.lat"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Longitude</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.lng"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Entrance Count</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.no_entrances"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Max Capacity</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-text-field
                solo
                background-color="grey lighten-3"
                flat
                :value="zone.level_max"
                hide-details
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="2"
              sm="2"
              xs="2"
              md="2"
              lg="2"
              xl="2"
              class="text-right pr-2 align-self-center"
            >
              <span>Image</span>
            </v-col>
            <v-col cols="10" class="text-start">
              <v-img
                v-if="zone.image"
                :src="zone.image"
                max-height="250"
                cover
                max-width="250"
              ></v-img>
              <span v-else class="pl-2">NA</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          xs="12"
          md="6"
          lg="6"
          xl="6"
        >
          <v-row no-gutters class="text-end">
            <v-col cols="6" class="align-self-center">
              <span class="text-h6">Map view</span>
            </v-col>
            <v-col cols="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    class="elevation-0"
                    v-bind="attrs"
                    v-on="on"
                    @click="navigateToEditZone(zone.zid)"
                  >
                    <v-icon color="primary"> edit </v-icon>
                  </v-btn>
                </template>
                <span>Edit zone</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <gmap-map
            :center="{ lat: Number(zone.lat), lng: Number(zone.lng) }"
            :zoom="18"
            style="width: 100%; height: 90%"
          >
            <gmap-marker
              :key="zone.name"
              :animation="2"
              :position="{ lat: Number(zone.lat), lng: Number(zone.lng) }"
            ></gmap-marker>
          </gmap-map>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAx6xCuQltmAGq-mCi87DINTvMwI1lrXJc",
    v: "3.31",
  },
});
export default {
  name: "ZoneOverview",
  props: {
    zone: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {},
  mounted() {},
  methods: {
    navigateToEditZone(zid) {
      this.$router.push({ path: "/edit/zone/" + zid });
    },
  },
};
</script>
<style lang="sass">
.vue-map
  width: 100%
  height: 100%
  position: absolute !important
</style>
