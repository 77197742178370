<template>
  <v-card flat style="background-color: #f4f6f9" class="pt-1">
    <v-card-text>
      <!-- {{fillChartData(last_week_data)}} -->
      <line-chart
      v-if="beaconStaus.length > 0"
        :options="options"
        :chart-data="fillChartData(beaconStaus)"
      ></line-chart>
      <span v-else class="text-center justify-center"> No data availabe</span>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChart from "@/lib/LineChart.js";
// import { EventBus } from "@/lib/EventBus";
import { mapGetters } from "vuex";
// import * as API from "@/api/api";
export default {
  name: "HardwareStatus",
  components: { LineChart },
  props: {
    beaconStaus: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      datacollection: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "top",
          labels: {
            fontSize: 16,
            fontColor: "black",
            boxWidth: 20,
            fontFamily: "lato",
          },
        },
        tooltips: {
          mode: "x-axis",
        },
        // plugins: {
        //   title: {
        //     display: true,
        //     text: "",
        //   },
        //   tooltip: {
        //     mode: "nearest",
        //     intersect: true,
        //   },
        // },
        // hover: {
        //   mode: "nearest",
        //   intersect: true,
        // }
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedZone: "pinaStore/getterSelectedZone",
    }),
  },
  methods: {
    fillChartData() {
      let datasets = [];
      for (let p = 0; p < this.beaconStaus.length; p++) {
        datasets.push({
          label: this.beaconStaus[p].beaconData[0].name,
          data: this.beaconStaus[p].beaconData.map((a) => a.batteryLevel),
          borderColor: this.getRandomColor(),
          fill: false,
          lineTension: 0,
        });
      }
      var data = {
        labels:
          this.beaconStaus.length > 0
            ? this.beaconStaus[0].beaconData.map((a) => a.month_name)
            : [],
        datasets: datasets,
      };
      return data;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
};
</script>
