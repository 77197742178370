<template>
  <v-card flat style="background-color: #f4f6f9" class="pt-1">
    <v-container fluid px-0 pt-1 v-if="zoneEntrances.hasOwnProperty('entranceList')">
      <!-- {{ zoneEntrances }} -->
      <v-row no-gutters>
        <v-col cols="12">
          <v-expansion-panels accordion focusable v-model="openExpPanel">
            <v-expansion-panel
              v-for="(zone, i) in zoneEntrances.entranceList"
              :key="'entrance-' + i"
              multiple
            >
              <v-expansion-panel-header>
               <span> {{ zone.name}}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="mt-2 font-weight-bold">In Lanes</p>
                <v-data-table
                  :headers="entranceHeaders"
                  :items="zone['IN'].Lanes"
                  hide-default-footer
                  dense
                  class="pa-2"
                >
                  <template v-slot:[`item.loop_status`]="{item}">
                    {{
                      item.loop_status == "0" ? 'Disabled' : 'Enabled'
                    }}
                  </template>
                  <template v-slot:[`item.status`]="{item}">
                    {{
                      item.status == "0" ? 'Inactive' : 'Active'
                    }}
                  </template>
                  <template v-slot:[`item.is_reversible`]="{item}">
                    {{
                      item.is_reversible == "0" ? 'No' : 'Yes'
                    }}
                  </template>
                  <template v-slot:[`item.zone_mobile_no`]="{}">
                    {{
                      zoneEntrances.zone_mobile_no
                        ? zoneEntrances.zone_mobile_no
                        : "-"
                    }}
                  </template>
                  <template v-slot:[`item.actions`]="{item}"
                    ><v-btn
                      x-small
                      elevation="0"
                      color="primary"
                      @click="
                        displayQRDialog(
                          zoneEntrances.entranceList[i].name,
                          item.lane_text_current,
                          item.minorid
                        )
                      "
                      >Display QR Image</v-btn></template
                  >
                  <template v-slot:no-data>
                    <span>No data available</span>
                  </template>
                </v-data-table>
                <p class="mt-2 font-weight-bold">Out Lanes</p>
                <v-data-table
                  :headers="entranceHeaders"
                  :items="zone['OUT'].Lanes"
                  hide-default-footer
                  dense
                  class="pa-2"
                >
                <template v-slot:[`item.loop_status`]="{item}">
                    {{
                      item.loop_status == "0" ? 'Disabled' : 'Enabled'
                    }}
                  </template>
                  <template v-slot:[`item.status`]="{item}">
                    {{
                      item.status == "0" ? 'Inactive' : 'Active'
                    }}
                  </template>
                  <template v-slot:[`item.is_reversible`]="{item}">
                    {{
                      item.is_reversible == "0" ? 'No' : 'Yes'
                    }}
                  </template>
                  <template v-slot:[`item.zone_mobile_no`]="{}">
                    {{
                      zoneEntrances.zone_mobile_no
                        ? zoneEntrances.zone_mobile_no
                        : "-"
                    }}
                  </template>
                  <template v-slot:[`item.actions`]="{item}">
                    <v-btn
                      x-small
                      elevation="0"
                      color="primary"
                      @click="
                        displayQRDialog(
                          zoneEntrances.entranceList[i].name,
                          item.lane_text_current,
                          item.minorid
                        )
                      "
                      >Display QR Image</v-btn></template
                  >
                  <template v-slot:no-data>
                    <span>No data available</span>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="qrDialog" max-width="600">
      <v-card>
        <v-card-title class="headline text-center">
          <span>{{ qrDialogTitle }}</span>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="downloadQRImage()"
              >
                download
              </v-icon>
            </template>
            <span>Download QR Image</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center">
          <vue-qrcode
            ref="qrImage"
            :value="qrText"
            :options="{ width: 500, errorCorrectionLevel: 'M' }"
          ></vue-qrcode>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import jsPDF from "jspdf";
export default {
  name: "ZoneEntrances",
  components: { VueQrcode },
  props: {
    zone: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      openExpPanel:0,
      qrDialog: false,
      qrDialogTitle: "",
      qrText: "",
      entranceHeaders: [
        {
          text: "Lane",
          align: "center",
          value: "lane_text_current",
        },
        { text: "Reversible Lane", value: "is_reversible", align: "center" },
        { text: "SMS/Dial-In No", value: "zone_mobile_no", align: "center" },
        { text: "SMS/Dial-In Code", value: "sms_code", align: "center" },
        { text: "Lane Status", value: "status", align: "center" },
        { text: "Loop Status", value: "loop_status", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      expand: [0],
    };
  },
  computed: {
    zoneEntrances() {
      if (this.$store.getters["pinaStore/getterZoneEntrancesLength"] > 0) {
        return this.$store.getters["pinaStore/getterZoneEntrancesByZoneId"](
          this.$store.getters["pinaStore/getterSelectedZone"].zid
        );
      }
      return [];
    },
    // expand(){
    //   return [0]
    // }
  },
  mounted() {},
  methods: {
    displayQRDialog(entranceName, laneName, minorid) {
      this.qrDialog = true;
      this.qrDialogTitle = entranceName + " - " + laneName;
      this.qrText =
        "divrt://" +
        this.zoneEntrances.mmid +
        "/" +
        this.zoneEntrances.majorid +
        "/" +
        minorid;
    },
    downloadQRImage() {
      var doc = new jsPDF('portrait', 'pt', 'a4', true);
      var pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.setFontSize(20);
      var entrancexOffset =
        pageWidth / 2 -
        (doc.getStringUnitWidth(this.qrDialogTitle) *
          doc.internal.getFontSize()) /
          2;
      doc.text(this.qrDialogTitle, entrancexOffset, pageHeight / 6);
      doc.setFontSize(30);
      var titlexOffset =
        pageWidth / 2 -
        (doc.getStringUnitWidth(this.zoneEntrances.name) *
          doc.internal.getFontSize()) /
          2;
      doc.text(this.zoneEntrances.name , titlexOffset, pageHeight / 6 - 40);
      var imagexOffset = pageWidth - this.$refs.qrImage.$el.clientWidth;
      doc.addImage(
        this.$refs.qrImage.$el.toDataURL("image/JPEG"),
        "JPEG",
        imagexOffset,
        pageHeight / 4
      );
      doc.save("QRImage.pdf");
    },
  },
};
</script>
