async function askWritePermission() {
    try {
        const { state } = await navigator.permissions.query({ name: 'clipboard-write' })
        return state === 'granted'
    } catch (error) {
        return false
    }
}

const setToClipboard = async blob => {
    const data = [new window.ClipboardItem({ [blob.type]: blob })] 
    
    await navigator.clipboard.write(data)
}

const copyImageToClipboard = async imgSrc => {
    const options = {
        method: 'GET',
        headers: new Headers({'content-type': 'image/jpeg'}),
        mode: 'no-cors'
    }
    const response =  fetch(imgSrc,options).then((a)=>{console.log(a)});
    let blob = await response.blob();
    blob = blob.slice(0, blob.size, "image/png")
    await setToClipboard(blob);
}
const copyTextToClipboard = async text => {
    // if (canWriteToClipboard) {
        const blob = new Blob([text], { type: 'text/plain' })
        await setToClipboard(blob)
    // }
}

export { askWritePermission, copyTextToClipboard, copyImageToClipboard, setToClipboard }