<template>
  <v-card flat style="background-color: #f4f6f9" class="pt-1">
    <v-tabs v-model="hardwareTab" background-color="transparent" grow>
      <v-tab>Assigned Components</v-tab>
      <v-tab>Unassigned Components</v-tab>
    </v-tabs>

    <v-tabs-items v-model="hardwareTab">
      <v-tab-item>
        <v-container fluid pa-0>
          <v-row no-gutters>
            <v-col cols="12" sm="12" xs="12" md="2" lg="2" xl="2" class="pa-0">
              <v-list v-if="components.length > 0">
                <v-list-item-group v-model="selectedComponent" color="primary">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        >Select All Components</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="(component, i) in components" :key="i">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="component.component_name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              xs="12"
              md="10"
              lg="10"
              xl="10"
              class="pl-1"
            >
              <v-data-table
                :headers="hardwareManagementHeaders"
                :items="filterComponentsData"
                hide-default-footer
                class="pa-2"
              >
                <template v-slot:[`item.component_id`]="{ item }">
                  {{
                    item.component_id
                      ? getComponentName(item.component_id)
                      : "-"
                  }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-select
                    solo
                    flat
                    :width="200"
                    class="pa-1"
                    :items="filterActionsByComponentId(item.component_id)"
                    height="10px"
                    label="Select Action"
                    item-text="name"
                    item-value="action_id"
                    hide-details
                    v-model="item.optionSelected"
                  ></v-select>
                </template>
                <template v-slot:[`item.action_btn`]="{ item }">
                  <v-btn
                    x-small
                    class="elevation-0"
                    color="primary"
                    @click="sendAction(item, item.optionSelected)"
                    >Send</v-btn
                  >
                </template>
                <template v-slot:[`item.action_response`]="{ item }">
                  <p
                    v-if="item.action_response"
                    style="
                      width: 150px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    "
                  >
                    {{ item.action_response }}
                  </p>
                  <v-btn icon x-small color="primary" @click="copyContent(item.action_response)">
                    <v-icon>content_copy</v-icon></v-btn
                  >
                </template>
                <template v-slot:no-data>
                  <span>No data available</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-data-table
          :headers="unAssignedComponentHeaders"
          :items="unAssignedComponentsData"
          hide-default-footer
          class="pa-2"
        >
          <template v-slot:[`item.action_btn`]="{ item }">
            <v-btn
              x-small
              class="elevation-0"
              color="primary"
              @click="configureComponent(item)"
              >Configure</v-btn
            >
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog v-model="configurePinaboxDialog" max-width="600">
      <v-card style="background-color: #f4f6f9">
        <v-card-title class="headline text-center">
          <span>Configure Pinabox</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-3">
          <v-container fluid pa-0>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Serial No
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  flat
                  :value="selectedPinabox.serialNo"
                  hide-details
                  disabled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Zone
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  solo
                  flat
                  :items="unAssignedPinaboxZones"
                  label="Select Zone"
                  item-text="name"
                  item-value="zid"
                  hide-details
                  @change="onUnAssignedZoneChange()"
                  v-model="selectedUnassignedPinaboxZone"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Entrance
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  solo
                  flat
                  :items="selectedUnassignedPinaboxZoneEntrances"
                  label="Select Entrance"
                  :disabled="
                    !(
                      selectedUnassignedPinaboxZone != null &&
                      selectedUnassignedPinaboxZone != ''
                    )
                  "
                  item-text="entrance_name"
                  item-value="entrance_id"
                  hide-details
                  v-model="selectedUnassignedPinaboxZoneEntrance"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Gate Type
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  solo
                  flat
                  :items="gateTypes"
                  label="Select Gate type"
                  :disabled="
                    !(
                      selectedUnassignedPinaboxZoneEntrance != null &&
                      selectedUnassignedPinaboxZoneEntrance != ''
                    )
                  "
                  hide-details
                  v-model="selectedUnassignedPinaboxZoneGate"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Lane
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-select
                  solo
                  flat
                  :items="filteredLaneList"
                  item-text="lane_text_current"
                  item-value="id"
                  label="Select Lane"
                  :disabled="
                    !(
                      selectedUnassignedPinaboxZoneEntrance != null &&
                      selectedUnassignedPinaboxZoneEntrance != ''
                    )
                  "
                  hide-details
                  v-model="selectedUnassignedPinaboxZoneLane"
                  @change="onUnassignedPinaboxZoneLaneChange()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Cmd Topic
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  flat
                  v-model="cmdTopic"
                  hide-details
                  disabled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                cols="4"
                sm="4"
                xs="4"
                md="4"
                lg="4"
                xl="4"
                class="text-right pr-2 align-self-center"
              >
                Status Topic
              </v-col>
              <v-col cols="8" sm="8" xs="8" md="8" lg="8" xl="8">
                <v-text-field
                  solo
                  flat
                  v-model="statusTopic"
                  hide-details
                  disabled
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="configurePinaboxDialog = false">Cancel</v-btn>
          <v-btn
            color="primary"
            class="elevation-0"
            :loading="configurePinaboxBtnLoading"
            :disabled="isConfigurePinaboxBtnDisabled"
            @click="configurePinaboxComponent()"
            >Configure</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// import { mapGetters } from "vuex";
import * as API from "@/api/api";
import * as copy from "@/utils/copyToClipboard";
// import { EventBus } from "@/lib/EventBus";
export default {
  name: "HardwareManagement",
  components: {},
  props: {
    zone: {
      type: Object,
      default: function () {
        return {};
      },
    },
    components: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      configurePinaboxDialog: false,
      selectedUnassignedPinaboxZone: "",
      selectedUnassignedPinaboxZoneEntrance: "",
      selectedUnassignedPinaboxZoneGate: "",
      selectedUnassignedPinaboxZoneLane: "",
      selectedUnassignedPinaboxZoneEntrances: [],
      hardwareTab: null,
      selectedComponent: 0,
      selectedPinabox: {},
      componentHeaders: [
        {
          text: "Select All Components",
          align: "center",
          value: "component_name",
        },
      ],
      componentsData: [],
      componentActionsData: [],
      unAssignedComponentsData: [],
      hardwareManagementHeaders: [
        {
          text: "Component",
          align: "center",
          value: "component_id",
        },
        { text: "Identifier", value: "identifier", align: "center" },
        { text: "Lane Name", value: "lane_name", align: "center" },
        {
          text: "Last Activity Time",
          value: "last_activity_time",
          align: "center",
        },
        { text: "Status", value: "status", align: "center" },
        // { text: "Loop Status", value: "loop_status", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
        { text: "Action Button", value: "action_btn", align: "center" },
        {
          text: "Action Response",
          value: "action_response",
          align: "center",
          width: "150px",
        },
      ],
      unAssignedComponentHeaders: [
        {
          text: "Pinabox Serial No",
          align: "center",
          value: "serialNo",
        },
        { text: "Status", value: "status", align: "center" },
        { text: "Action", value: "action_btn", align: "center" },
      ],
      unAssignedPinaboxZones: [],
      gateTypes: [],
      laneList: [],
      cmdTopic: "",
      statusTopic: "",
    };
  },
  computed: {
    filterComponentsData() {
      let filteredComponents =
        this.getComponentIdByIndex() == 0
          ? this.componentsData
          : this.componentsData.length > 0
          ? this.componentsData.filter(
              (c) => c.component_id == this.getComponentIdByIndex()
            )
          : [];
      return filteredComponents;
    },
    filteredLaneList() {
      let list =
        this.laneList.length > 0
          ? this.laneList.filter(
              (lane) =>
                lane.lane_type_current == this.selectedUnassignedPinaboxZoneGate
            )
          : [];
      return list;
    },
    configurePinaboxBtnLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "CONFIGURE_PINABOX"
      );
    },
    isConfigurePinaboxBtnDisabled() {
      let isDisabled =
        this.selectedUnassignedPinaboxZone == "" ||
        this.selectedUnassignedPinaboxZoneEntrance == "" ||
        this.selectedUnassignedPinaboxZoneLane == "" ||
        this.selectedUnassignedPinaboxZoneGate == "";
      return isDisabled;
    },
  },
  watch: {
    zone() {
      this.fetchAllHardwareComponents();
    },
  },
  mounted() {
    this.fetchAllHardwareComponents();
    this.fetchUnassignedPinaboxComponents();
  },
  methods: {
    async copyContent(data){
      await copy.copyTextToClipboard(JSON.stringify(data));
      this.$notify({
          group: "divrt",
          title: "Success",
          text: "Copied to clipboard successfully",
          type: "success",
        })
    },
    async fetchAllHardwareComponents() {
      const result = this.zone?.zid
        ? await API.getHardwareComponents({ zid: this.zone.zid })
        : {};
      this.componentsData = result?.data?.data ? result.data.data : [];
      this.componentActionsData = result?.data?.componentActions
        ? result.data.componentActions
        : [];
    },
    async fetchUnassignedPinaboxComponents() {
      const result = this.zone?.zid ? await API.getPinaboxComponents({}) : {};
      this.unAssignedComponentsData = result?.data?.data
        ? result.data.data
        : [];
    },
    getComponentName(component_id) {
      const componentObj = this.components.find(
        (c) => c.component_id == component_id
      );
      return componentObj?.component_name ? componentObj.component_name : "";
    },
    async configureComponent(item) {
      this.configurePinaboxDialog = true;
      this.selectedPinabox = Object.assign({}, item);
      const result = await API.getUnassignedPinaboxZones({});
      this.unAssignedPinaboxZones = result?.data?.data ? result.data.data : [];
    },
    filterActionsByComponentId(componentId) {
      return this.componentActionsData.filter(
        (c) => c.component_id == componentId
      );
    },
    getComponentIdByIndex() {
      if (this.selectedComponent == 0) {
        return 0;
      }
      const componentObj = this.components[this.selectedComponent - 1];
      return componentObj?.component_id ? componentObj.component_id : "";
    },
    async configurePinaboxComponent() {
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "CONFIGURE_PINABOX",
        pending: true,
      });
      let postData = {
        zid: this.selectedUnassignedPinaboxZone,
        zone_config_entrance_index: this.selectedUnassignedPinaboxZoneEntrance,
        entrance_id: this.selectedUnassignedPinaboxZoneEntrance,
        pinaboxSerialNo: this.selectedPinabox.serialNo,
      };
      const result = await API.configurePinabox(postData);
      this.configurePinaboxDialog = false;
      this.$notify({
        group: "divrt",
        title: result?.data?.status && result.data.status ? "Success" : "Error",
        text: result?.data?.message ? result.data.message : "",
        type: result?.data?.status && result.data.status ? "success" : "error",
      });
      this.$store.commit("pinaStore/SET_LOADING_STATUS", {
        property: "CONFIGURE_PINABOX",
        pending: false,
      });
      this.fetchAllHardwareComponents();
    },
    onUnassignedPinaboxZoneLaneChange() {
      let lane = this.laneList.find(
        (lane) => lane.id == this.selectedUnassignedPinaboxZoneLane
      );
      this.cmdTopic = lane?.base_cmd_topic ? lane.base_cmd_topic : "";
      this.statusTopic = lane?.base_status_topic ? lane.base_status_topic : "";
    },
    // onActionChange(item, action) {

    //   console.log(action);
    //   console.log(item)
    // },
    async onUnAssignedZoneChange() {
      const result = await API.getZoneInfo({
        zid: this.selectedUnassignedPinaboxZone,
      });
      this.selectedUnassignedPinaboxZoneEntrances = result?.data?.entranceList
        ? result.data.entranceList
        : [];
      this.gateTypes = result?.data?.gateTypes ? result.data.gateTypes : [];
      this.laneList = result?.data?.laneList ? result.data.laneList : [];
    },
    async sendAction(item, action) {
      const result =
        action != ""
          ? await API.configHardwareComponent({
              id: item.id,
              action_id: action,
            })
          : {};
      // let k = Object.assign({}, , {action_response:result.data.message});
      this.$set(
        this.filterComponentsData.find((c) => c.id == item.id),
        "action_response",
        JSON.stringify(result.data.message)
      );
      // return result?.data?.message ? result.data.message : "";
    },
  },
};
</script>
