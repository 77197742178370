<template>
  <v-container fluid>
    <v-row no-gutters>
      <!-- <v-col cols="2" sm="2" xs="2" md="2" lg="2" xl="2" class="pa-2">
        <v-list>
          <v-subheader>Zones</v-subheader>
          <v-divider></v-divider>
          <v-list-item-group v-model="zoneSelected" color="primary">
            <v-list-item v-for="(item) in zones" :key="item.zid">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col> -->
      <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12" class="pa-2">
        <v-card flat>
          <v-tabs v-model="tab" background-color="transparent" grow>
            <v-tab>Overview</v-tab>
            <v-tab>Entrances</v-tab>
            <v-tab v-show="ltype == 'divrt'">Hardware Status</v-tab>
            <v-tab v-show="ltype == 'divrt'">Hardware Management</v-tab>
            <!-- <v-tab>Allowed Plates</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <zone-overview :zone="selectedZone"></zone-overview>
            </v-tab-item>
            <v-tab-item>
              <zone-entrances :zone="selectedZone"></zone-entrances>
            </v-tab-item>
            <v-tab-item>
              <hardware-status :beacon-staus="beaconStatus"></hardware-status>
            </v-tab-item>
            <v-tab-item>
              <hardware-management
                :zone="selectedZone"
                :components="components"
              ></hardware-management>
            </v-tab-item>
            <!-- <v-tab-item>
              <allowed-plates :plates="userPlatesData"></allowed-plates>
            </v-tab-item> -->
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import * as API from "@/api/api";
import ZoneOverview from "@/components/zone/ZoneOverview.vue";
import { mapGetters } from "vuex";
import * as API from "@/api/api";
import ZoneEntrances from "../components/zone/ZoneEntrances.vue";
import HardwareManagement from "../components/zone/HardwareManagement.vue";
import HardwareStatus from "../components/zone/HardwareStatus.vue";
// import AllowedPlates from "../components/zone/AllowedPlates.vue";
import { EventBus } from "@/lib/EventBus";
export default {
  name: "ReportsView",
  components: {
    ZoneOverview,
    ZoneEntrances,
    HardwareManagement,
    // AllowedPlates,
    HardwareStatus
  },
  data() {
    return {
      // zoneSelected:"",
      // selectZone:0,
      tab: null,
      userPlatesData: [],
      beaconStatus:[]
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //   });
  // },
  computed: {
    ...mapGetters({
      zones: "pinaStore/getterZones",
      selectedZone: "pinaStore/getterSelectedZone",
      components: "pinaStore/getterComponentsList",
      uid:"authStore/getterUID",
      ltype:"authStore/getterUserLType"
    }),
    // zoneSelected : {
    //   // get: function(){
    //   //   return this.selectedZone?.zid ? this.zones.findIndex(a => a.zid == this.selectedZone.zid) : 0
    //   // },
    //   // set: function(newValue){
    //   //    console.log(newValue)
    //   //   //  let zoneObj = this.zones.findIndex(a => a.zid == newValue);
    //   //   //  this.$store.commit("pinaStore/SET_SELECTED_ZONE", zoneObj);
    //   //   // this.zoneSelected = newValue;

    //   // }
    // }
  },
  watch: {
    // selectedZone(newValue){
    //   this.selectZone =  newValue;
    // }
  },
  mounted() {
    this.fetchAllZoneSummary();
    this.fetchComponentsList();
     // this.fetchUserPlateList();
    this.getBeaconStatus()
    EventBus.$on("zone-changed", () => {
      // this.fetchUserPlateList();
      this.getBeaconStatus();
      this.fetchAllZoneSummary();
      this.fetchComponentsList();
    });
    // EventBus.$on("user-plate-deleted", () => {
    //   this.fetchUserPlateList();
    // });
  },
  methods: {
   async getBeaconStatus(){
      const bStatus = this.selectedZone?.zid
          ? await API.beaconStatus({ zid: this.selectedZone.zid}) : [];
      
      this.beaconStatus = bStatus?.data?.data ? bStatus.data.data : []

    },
    fetchAllZoneSummary() {
      this.$store.dispatch("pinaStore/getAllZoneEntrances", {
        uid: this.uid,
        zid: "",
      });
      // this.$notify({
      //     group: "divrt",
      //     title: "Success",
      //     text: "Fetched zone details successfully",
      //     type: "success",
      //   });
    },
    fetchComponentsList() {
      this.$store.dispatch("pinaStore/getComponentsList", {});
      // this.$notify({
      //     group: "divrt",
      //     title: "Success",
      //     text: "Fetched components successfully",
      //     type: "success",
      //   });
    },
    // async fetchUserPlateList() {
    //   let allowedPlatesData = this.selectedZone?.zid
    //     ? await API.getUserAllowedPlates({ zid: this.selectedZone.zid })
    //     : {};
    //   // this.$notify({
    //   //   group: "divrt",
    //   //   title: "Success",
    //   //   text: "Fetched allowed plates successfully",
    //   //   type: "success",
    //   // });
    //   this.userPlatesData = allowedPlatesData?.data?.data
    //     ? allowedPlatesData.data.data
    //     : [];
    // },
  },
};
</script>
