var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-1",staticStyle:{"background-color":"#f4f6f9"},attrs:{"flat":""}},[(_vm.zoneEntrances.hasOwnProperty('entranceList'))?_c('v-container',{attrs:{"fluid":"","px-0":"","pt-1":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"accordion":"","focusable":""},model:{value:(_vm.openExpPanel),callback:function ($$v) {_vm.openExpPanel=$$v},expression:"openExpPanel"}},_vm._l((_vm.zoneEntrances.entranceList),function(zone,i){return _c('v-expansion-panel',{key:'entrance-' + i,attrs:{"multiple":""}},[_c('v-expansion-panel-header',[_c('span',[_vm._v(" "+_vm._s(zone.name))])]),_c('v-expansion-panel-content',[_c('p',{staticClass:"mt-2 font-weight-bold"},[_vm._v("In Lanes")]),_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.entranceHeaders,"items":zone['IN'].Lanes,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.loop_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.loop_status == "0" ? 'Disabled' : 'Enabled')+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "0" ? 'Inactive' : 'Active')+" ")]}},{key:"item.is_reversible",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_reversible == "0" ? 'No' : 'Yes')+" ")]}},{key:"item.zone_mobile_no",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.zoneEntrances.zone_mobile_no ? _vm.zoneEntrances.zone_mobile_no : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.displayQRDialog(
                        _vm.zoneEntrances.entranceList[i].name,
                        item.lane_text_current,
                        item.minorid
                      )}}},[_vm._v("Display QR Image")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No data available")])]},proxy:true}],null,true)}),_c('p',{staticClass:"mt-2 font-weight-bold"},[_vm._v("Out Lanes")]),_c('v-data-table',{staticClass:"pa-2",attrs:{"headers":_vm.entranceHeaders,"items":zone['OUT'].Lanes,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.loop_status",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.loop_status == "0" ? 'Disabled' : 'Enabled')+" ")]}},{key:"item.status",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.status == "0" ? 'Inactive' : 'Active')+" ")]}},{key:"item.is_reversible",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_reversible == "0" ? 'No' : 'Yes')+" ")]}},{key:"item.zone_mobile_no",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.zoneEntrances.zone_mobile_no ? _vm.zoneEntrances.zone_mobile_no : "-")+" ")]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.displayQRDialog(
                        _vm.zoneEntrances.entranceList[i].name,
                        item.lane_text_current,
                        item.minorid
                      )}}},[_vm._v("Display QR Image")])]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("No data available")])]},proxy:true}],null,true)})],1)],1)}),1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.qrDialog),callback:function ($$v) {_vm.qrDialog=$$v},expression:"qrDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('span',[_vm._v(_vm._s(_vm.qrDialogTitle))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.downloadQRImage()}}},'v-icon',attrs,false),on),[_vm._v(" download ")])]}}])},[_c('span',[_vm._v("Download QR Image")])])],1),_c('v-divider'),_c('v-card-text',{staticClass:"text-center"},[_c('vue-qrcode',{ref:"qrImage",attrs:{"value":_vm.qrText,"options":{ width: 500, errorCorrectionLevel: 'M' }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }